import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { IntlProvider } from 'react-intl'
// import { get } from 'lodash'

import configureStore, { history } from '@monorepo/core/store/configureStore'
import { debug } from '@monorepo/core/config'

import App from '@monorepo/core/containers/App'
import routes from 'App/Resources/routes'
import * as serviceWorker from './serviceWorker'
import 'App/initializers'
import messages from 'App/Resources/translations'
import { AppProvider } from 'packages/app_module'
// import history from '@monorepo/core/utils/history'
import 'es6-shim'
import allSettled from 'promise.allsettled'
allSettled.shim()

if (debug) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

export const formats = {
  number: {
    RUB: {
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currency: 'RUB',
    },
    EUR: {
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currency: 'EUR',
    },
    USD: {
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currency: 'USD',
    },
  },
}

// if (!Intl.PluralRules) {
//   require('intl-pluralrules')
// }

// if (!Intl.RelativeTimeFormat) {
//   const locales = ['ru', 'en']
//   require('@formatjs/intl-relativetimeformat/polyfill')
//   // require('@formatjs/intl-relativetimeformat/dist/include-aliases') // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
//   locales.forEach(locale =>
//     require(`@formatjs/intl-relativetimeformat/dist/locale-data/${locale}`)
//   )
//   // require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
//   // require('@formatjs/intl-relativetimeformat/dist/locale-data/ru')
// }

const store = configureStore({} /*, history*/)
const locale = 'ru'
const app = (
  <IntlProvider
    messages={messages?.[locale] ?? {}}
    locale={locale}
    formats={formats}
  >
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <AppProvider>
          <App routes={routes(store)} locale={locale} formats={formats} />
        </AppProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </IntlProvider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// debug ? serviceWorker.unregister() : serviceWorker.register()
serviceWorker.unregister()
