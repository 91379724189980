import React from 'react'

import { useAdvertisingSettings } from '@monorepo/advertising_module'
import { AdvertisementWindow } from 'App/Components/AdvertisementWindow'

type AnnouncementsProps = {
  //
}

export const Announcements: React.FC<AnnouncementsProps> = () => {
  const {
    modalAction,
    isShowModal,
    hideModalHandler,
  } = useAdvertisingSettings()

  return (
    <>
      {modalAction && (
        <AdvertisementWindow
          item={modalAction}
          show={isShowModal}
          onHide={hideModalHandler}
        />
      )}
    </>
  )
}
