import React from 'react'

import {
  LightBox,
  Image as BaseImage,
  ImageProps,
} from '@monorepo/components/common'
import { ImagePlaceholder } from 'App/Components'

type GoodImageProps = ImageProps & {
  src?: string[] | string
}

export const Image: React.FC<GoodImageProps> = ({ src, ...props }) =>
  !!src ? (
    <LightBox images={src}>
      <BaseImage
        {...props}
        src={Array.isArray(src) ? src[0] : src}
        unloader={<ImagePlaceholder {...props} />}
      />
    </LightBox>
  ) : (
    <ImagePlaceholder {...props} />
  )
