import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill'

async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-pluralrules/polyfill')
  }

  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-pluralrules/locale-data/en')
        break
      case 'ru':
        await import('@formatjs/intl-pluralrules/locale-data/ru')
        break
    }
  }
}

if (!Intl.PluralRules) {
  require('intl-pluralrules')
}

polyfill()
polyfill('ru')
