import React from 'react'

import { Bottom } from './Bottom'
import { Content } from './Content'

type FooterPropsType = {}

const Footer: React.FC<FooterPropsType> = () => (
  <footer className="site__footer">
    <div className="site-footer">
      <div className="decor site-footer__decor decor--type--bottom">
        <div className="decor__body">
          <div className="decor__start" />
          <div className="decor__end" />
          <div className="decor__center" />
        </div>
      </div>
      <Content />
      <Bottom />
    </div>
  </footer>
)

export default Footer
