import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/interfaces'
import { Link } from 'App/Components'

type MenuItemProps = React.HTMLAttributes<HTMLLIElement> & {
  item: MenuItemType
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  className,
  ...props
}) => {
  const LinkComponent = item.url ? Link : 'span'

  return (
    <li
      {...props}
      className={cn('departments__item', className, {
        'departments__item--submenu--megamenu': item.menu,
        'departments__item--has-submenu': item.menu,
      })}
    >
      <LinkComponent to={item.url} className="departments__item-link">
        {item.name}
        {item.menu && (
          <span className="departments__item-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width={7} height={11}>
              <path
                d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
C-0.1,9.8-0.1,10.4,0.3,10.7z"
              />
            </svg>
          </span>
        )}
      </LinkComponent>
    </li>
  )
}
