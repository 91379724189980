import React from 'react'
import gravatar from 'gravatar'

import { UserType } from '@monorepo/interfaces'
import { App } from '@monorepo/hooks'
import { Link } from 'App/Components'
import { Divider } from './Divider'
import { MenuItem } from './MenuItem'

type PropsType = {
  user: UserType
}

export const Menu: React.FC<PropsType> = ({ user }) => {
  const { menu } = App.useMenu('userButton')

  return (
    <>
      <Link to="/profile" className="account-menu__user">
        <div className="account-menu__user-avatar">
          <img
            src={gravatar.url(user.profile.email!, { default: 'mp' })}
            alt=""
          />
        </div>
        <div className="account-menu__user-info">
          <div className="account-menu__user-name">
            {user.profile.firstName}
          </div>
          <div className="account-menu__user-email">{user.profile.email}</div>
        </div>
      </Link>
      <Divider />
      <ul className="account-menu__links">
        {menu.map((item, idx) => (
          <MenuItem key={idx} {...item} />
        ))}
      </ul>
      <Divider />
      <ul className="account-menu__links">
        <MenuItem name="Выход" url="/account/logout" />
      </ul>
    </>
  )
}
