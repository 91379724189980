import React from 'react'

import { MenuType } from '@monorepo/interfaces'
import { Link } from 'App/Components'

export type LinksColumnProps = {
  title: React.ReactNode
  items: MenuType
}

export const LinksColumn: React.FC<LinksColumnProps> = ({ title, items }) => (
  <div className="site-footer__widget footer-links">
    <h5 className="footer-links__title">{title}</h5>
    <ul className="footer-links__list">
      {items.map((item, idx) => (
        <li key={idx} className="footer-links__item">
          <Link key={idx} to={item.url} className="footer-links__link">
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)
