import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentUser } from '@monorepo/core/user_module/useCurrentUser'
import { Filter } from '../api/fetch_campaigns_list.api'
import { useApi } from '../api/useApi'
import { IAdvertisingCampaign } from '../interfaces'
import { QUERY_KEY } from './campaing.hook'

export type { Filter as CampaingsListFilter } from '../api/fetch_campaigns_list.api'

export function useCampaingsList<TData = IAdvertisingCampaign[]>(
  filter?: Filter,
  options?: UseQueryOptions<IAdvertisingCampaign[], AxiosError, TData>
) {
  const { fetchCampaignsList } = useApi()
  const { data: user, isFetching: isUserFetching } = useCurrentUser()

  return useQuery(
    [QUERY_KEY, { user: user?.id, ...filter }] as any,
    fetchCampaignsList.bind(null, filter),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      enabled: !isUserFetching,
      ...options,
    }
  )
}
