import React from 'react'

// import { Link } from 'App/Components'
import { ErrorLayout } from './ErrorLayout'

type Props = {
  //
}

export const Error500: React.FC<Props> = () => (
  <ErrorLayout code="500" title="Произошла внутренняя ошибка">
    <p>
      Уважаемый пользователь, по каким-то причинам произошла внутренняя ошибка.
      <br />
      Если вы уверенны, что это случилось по нашей вине, пожалуйста, сообщите
      нам об этой проблеме и не забудьте указать адрес, с которого вы сюда
      попали.
    </p>
  </ErrorLayout>
)
