import React from 'react'

import { User } from '@monorepo/hooks'
import { Link } from 'App/Components'
import gravatar from 'gravatar'

type LoginPropsType = {
  //
}

export const Login: React.FC<LoginPropsType> = () => {
  const { user } = User.useUser()
  return (
    <div className="mobile-indicator d-none d-md-block">
      <Link
        to={user.isGuest ? '/account/login' : '/cabinet'}
        className="mobile-indicator__button"
      >
        <span className="mobile-indicator__icon">
          {user.profile ? (
            <img
              src={gravatar.url(user.profile.email!, { default: 'mp' })}
              className="rounded-circle"
              width={32}
              height={32}
              alt=""
            />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
              <path
                d="M20,20h-2c0-4.4-3.6-8-8-8s-8,3.6-8,8H0c0-4.2,2.6-7.8,6.3-9.3C4.9,9.6,4,7.9,4,6c0-3.3,2.7-6,6-6s6,2.7,6,6
c0,1.9-0.9,3.6-2.3,4.7C17.4,12.2,20,15.8,20,20z M14,6c0-2.2-1.8-4-4-4S6,3.8,6,6s1.8,4,4,4S14,8.2,14,6z"
              />
            </svg>
          )}
        </span>
      </Link>
    </div>
  )
}
