import React from 'react'
import { Helmet } from 'react-helmet'
import ErrorBoundary from 'react-error-boundary'

import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'

// import '../'
import { Metrics } from '@monorepo/components/common'
import { Layout } from './Layout'
import { Error500 } from 'App/View/Errors'

import './Resources/styles/style.scss'

export const App = ({ routes, location, locale, ...props }) => (
  <>
    <Helmet
      encodeSpecialCharacters={true}
      titleTemplate="%s | Auto Exclusive777 - Интернет-магазин автозапчастей"
      defaultTitle="Auto Exclusive777 - Интернет-магазин автозапчастей"
    >
      <html dir="ltr" lang={locale} amp />
      <meta charSet="utf-8" />
    </Helmet>
    <Metrics />
    <ToastContainer />
    <ErrorBoundary FallbackComponent={Error500}>
      <Layout>
        <ErrorBoundary FallbackComponent={Error500}>
          {renderRoutes(routes, props, { location })}
        </ErrorBoundary>
      </Layout>
    </ErrorBoundary>
  </>
)
