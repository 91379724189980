import React from 'react'

import {
  IAdvertisingCampaign,
  IAdvertisingCampaignSettings,
} from '../interfaces'

export interface IAdvertisingContext {
  toastActions?: IAdvertisingCampaign[]
  hideToastHandler(): void
  isShowToast: boolean
  modalAction?: IAdvertisingCampaign
  hideModalHandler(): void
  isShowModal: boolean
  settings?: IAdvertisingCampaignSettings
}

export const AdvertisingContext = React.createContext<IAdvertisingContext | null>(
  null
)
