import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/interfaces'
import { App } from '@monorepo/hooks'
import { Link, PhoneLink } from 'App/Components'
import { MenuPanel } from './MenuPanel'
import { IndicatorsList } from './IndicatorsList'
import { LinksList } from './LinksList'

type MobileMenuPropsType = {
  menu: MenuItemType[]
  show?: boolean
  ref?: React.Ref<HTMLDivElement>
  onHide?(): void
}

export const MobileMenu: React.FC<MobileMenuPropsType> = React.forwardRef<
  HTMLDivElement,
  MobileMenuPropsType
>(({ menu, show = false, onHide }, ref) => {
  const { company, mainPhone } = App.useCompany()

  return (
    <div className={cn('mobile-menu', { 'mobile-menu--open': show })}>
      <div className="mobile-menu__backdrop" />
      <div className="mobile-menu__body" ref={ref}>
        <button className="mobile-menu__close" type="button" onClick={onHide}>
          <svg width={12} height={12}>
            <path
              d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
	c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
	C11.2,9.8,11.2,10.4,10.8,10.8z"
            />
          </svg>
        </button>
        <MenuPanel title="Меню">
          <div className="mobile-menu__divider" />
          <IndicatorsList />
          <div className="mobile-menu__divider" />
          <LinksList items={menu} />
          <div className="mobile-menu__spring" />
          <div className="mobile-menu__divider" />
          <div className="mobile-menu__contacts">
            <Link to="/contacts">
              <div className="mobile-menu__contacts-subtitle">
                {company.address?.fullAddress}
              </div>
            </Link>
            {mainPhone && (
              <PhoneLink phone={mainPhone.number} className="text-reset">
                <div className="mobile-menu__contacts-title">
                  {mainPhone.number}
                </div>
              </PhoneLink>
            )}
          </div>
        </MenuPanel>
      </div>
    </div>
  )
})
