import * as React from 'react'
import invariant from 'invariant'
import { Form } from 'react-bootstrap'
import { isFunction, isUndefined } from 'lodash'
import { useField, ErrorMessage, FieldProps, FieldConfig } from 'formik'

type PropsType = FieldConfig & {
  label?: React.ReactNode
  isValid?: (props: Omit<FieldProps, 'form'>) => boolean | boolean
  isInvalid?: ((props: Omit<FieldProps, 'form'>) => boolean) | boolean
}

export const FormGroup: React.FC<PropsType> = ({
  children,
  label,
  isValid,
  isInvalid,
  ...props
}) => {
  const [field, meta /*helpers*/] = useField(props)

  invariant(
    isFunction(children) || React.isValidElement(children),
    'Children must be valid react element or function'
  )
  const extraProps = {
    ...(!isUndefined(isValid) && {
      isValid: isFunction(isValid) ? isValid({ field, meta }) : isValid,
    }),
    ...(!isUndefined(isInvalid) && {
      isInvalid: isFunction(isInvalid) ? isInvalid({ field, meta }) : isInvalid,
    }),
  }

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      {isFunction(children)
        ? children({ field, meta } as FieldProps)
        : React.cloneElement(children, { ...field, ...extraProps })}
      <ErrorMessage name={field.name}>
        {(msg: string) => (
          <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
        )}
      </ErrorMessage>
      {/* <ErrorMessage name={field.name}>
        {(msg: string) => (
          <Tooltip
            id={`${field.name}-error-tooltip`}
            className="invalid-tooltip show"
            placement="right"
          >
            {msg}
          </Tooltip>
        )}
      </ErrorMessage> */}
    </>
  )
}
