import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import styled from 'styled-components'

import { htmlParser } from '@monorepo/core/helpers'
import { IAdvertisingCampaign } from '@monorepo/advertising_module'

interface AdvertisementWindowProps extends ModalProps {
  item: IAdvertisingCampaign
}

const Body = styled(Modal.Body)`
  & p {
    hyphens: auto;
    hyphenate-limit-chars: 6 3 3;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 8%;
    text-align: justify;
    text-indent: 1.5em;
  }
`

const MainImage = styled.img`
  float: left !important;
  max-width: 40%;
  max-height: 300px;
  margin: 0 1.5em 1.5em 0;
`

export const AdvertisementWindow: React.FC<AdvertisementWindowProps> = ({
  item,
  ...props
}) => (
  <Modal {...props} size={item.image ? 'lg' : undefined} scrollable>
    <Modal.Header closeButton>
      <Modal.Title>{item.title}</Modal.Title>
    </Modal.Header>
    <Body>
      {item.image && <MainImage src={item.image} alt={item.title} />}
      {item.content ? htmlParser(item.content) : <p>{item.description}</p>}
    </Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Закрыть</Button>
    </Modal.Footer>
  </Modal>
)
