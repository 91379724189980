import React, { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { chain } from 'lodash'

import { MenuItemType } from '@monorepo/interfaces'
import { App } from '@monorepo/hooks'
import { PhoneLink } from 'App/Components'
import { Menu } from './Menu'
import { MenuButton } from './MenuButton'

type NavbarPropsType = {
  menu: MenuItemType[]
}

export const Navbar: React.FC<NavbarPropsType> = ({ menu }) => {
  const { company } = App.useCompany()

  const phones = useMemo(
    () =>
      chain(company.phones)
        .uniq()
        .take(2)
        .value(),
    [company.phones]
  )

  return (
    <div className="header__navbar">
      <MenuButton />
      <Menu items={menu} />
      <div className="header__navbar-phone phone d-flex">
        {phones.map(phone => (
          <OverlayTrigger
            key={phone.number}
            placement="auto"
            flip
            overlay={
              <Tooltip id={`tooltip-${phone.number}`}>
                {phone.name ?? 'Телефон'}
              </Tooltip>
            }
          >
            <PhoneLink
              key={phone.number}
              phone={phone.number}
              className="phone__body"
            >
              {/* <div className="phone__title">{phone.name}:</div> */}
              <small className="phone__number text-nowrap">
                {phone.number}
              </small>
            </PhoneLink>
          </OverlayTrigger>
        ))}
      </div>
    </div>
  )
}
