import { multiply } from 'lodash/fp'

export const toMs = multiply(60 * 1000)

export function setPrevTimeMs(prop: string, value: any) {
  try {
    localStorage.setItem(prop, String(value))
  } catch (error) {}
}

export function getPrevTimeMs(prop: string) {
  try {
    const value = localStorage.getItem(prop)
    return (value && parseInt(value)) || 0
  } catch (error) {}

  return 0
}

export function clearPrevTimeMs(prop: string) {
  try {
    localStorage.removeItem(prop)
  } catch (error) {}
}
