import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faTruck } from '@fortawesome/free-solid-svg-icons'

import { Currency } from '@monorepo/components/common'
import { App, User } from '@monorepo/hooks'
import { Link } from 'App/Components'

import { ContractsMenu } from './ContractsMenu'

type Props = {}

export const Toolbar: React.FC<Props> = () => {
  const {
    currentContract,
    orderContracts,
    selectContractHandler,
  } = App.useContract()
  const { user } = User.useUser()

  return (
    <div className="header__topbar-classic">
      <div className="topbar topbar--classic">
        <div className="topbar__item-text">
          <Link className="topbar__link" to="/about-us">
            О нас
          </Link>
        </div>
        <div className="topbar__item-text">
          <Link className="topbar__link" to="/contacts">
            Контакты
          </Link>
        </div>
        {/* <div className="topbar__item-text">
          <a className="topbar__link" href="/">
            Store Location
          </a>
        </div> */}
        {/* <div className="topbar__item-text">
          <a className="topbar__link" href="track-order.html">
            Track Order
          </a>
        </div> */}
        <div className="topbar__item-text">
          <Link className="topbar__link" to="/blog">
            Блог
          </Link>
        </div>
        <div className="topbar__item-text">
          <Link
            className="topbar__link text-uppercase text-light"
            to="/pages/arenda_specinstrumenta"
          >
            <FontAwesomeIcon icon={faTools} />
            &nbsp; Аренда инструмента
          </Link>
        </div>
        <div className="topbar__item-text">
          <Link
            className="topbar__link text-uppercase text-light"
            to="/pages/dostavka"
          >
            <FontAwesomeIcon icon={faTruck} />
            &nbsp; Бесплатная доставка
          </Link>
        </div>
        <div className="topbar__item-spring" />
        {/* <div className="topbar__item-button">
        <a href="/" className="topbar__button">
          <span className="topbar__button-label">Compare:</span>
          <span className="topbar__button-title">5</span>
        </a>
      </div> */}
        {currentContract && (
          <div className="topbar__item-button">
            <Link to="/balance" className="topbar__button">
              <span className="topbar__button-label">
                {currentContract.balance < 0
                  ? 'Задолженность'
                  : currentContract.balance > 0
                  ? 'Предоплата'
                  : 'Баланс'}
                :
              </span>
              <span
                className={cn('topbar__button-title ml-1 p-1', {
                  'bg-danger': currentContract.balance < 0,
                })}
              >
                <Currency value={Math.abs(currentContract.balance)} />
              </span>
            </Link>
          </div>
        )}
        {/* <div className="topbar__item-button topbar__menu">
          <button
            className="topbar__button topbar__button--has-arrow topbar__menu-button"
            type="button"
          >
            <span className="topbar__button-label">Currency:</span>
            <span className="topbar__button-title">USD</span>
            <span className="topbar__button-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="5px">
                <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
              </svg>
            </span>
          </button>
          <div className="topbar__menu-body">
            <a className="topbar__menu-item" href="/">
              € Euro
            </a>
            <a className="topbar__menu-item" href="/">
              £ Pound Sterling
            </a>
            <a className="topbar__menu-item" href="/">
              $ US Dollar
            </a>
            <a className="topbar__menu-item" href="/">
              ₽ Russian Ruble
            </a>
          </div>
        </div>
        <div className="topbar__menu">
          <button
            className="topbar__button topbar__button--has-arrow topbar__menu-button"
            type="button"
          >
            <span className="topbar__button-label">Language:</span>
            <span className="topbar__button-title">EN</span>
            <span className="topbar__button-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="5px">
                <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
              </svg>
            </span>
          </button>
          <div className="topbar__menu-body">
            <a className="topbar__menu-item" href="/">
              <img src="images/languages/language-1.png" alt="" />
              <span>English</span>
            </a>
            <a className="topbar__menu-item" href="/">
              <img src="images/languages/language-2.png" alt="" />
              <span>French</span>
            </a>
            <a className="topbar__menu-item" href="/">
              <img src="images/languages/language-3.png" alt="" />
              <span>German</span>
            </a>
            <a className="topbar__menu-item" href="/">
              <img src="images/languages/language-4.png" alt="" />
              <span>Russian</span>
            </a>
            <a className="topbar__menu-item" href="/">
              <img src="images/languages/language-5.png" alt="" />
              <span>Italian</span>
            </a>
          </div>
        </div> */}
        {!user.isGuest && (
          <ContractsMenu
            currentItem={currentContract}
            items={orderContracts}
            onSelect={selectContractHandler}
          />
        )}
      </div>
    </div>
  )
}
