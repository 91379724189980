import { useClient } from '@monorepo/core/api_module'

export type PriceLevelId = string

export interface IPriceLevel {
  id: PriceLevelId
  name: string
}

export interface IPriceCalculation {
  operations: IPriceCalculationOperation[]
  priceCreatedAt: string
  priceLevel: IPriceLevel
  purchasePrice: number
  retailPrice: number
  wholesalePrice: number
  fixedPrice: number | null
  minimumRetailPrice: number | null
}

interface IPriceCalculationOperation {
  name: string
  title?: string
  description?: string
  price?: number
  nextPrice?: number
}

export function useApi() {
  const client = useClient()

  async function fetchPriceInfo(itemKey?: string, priceLevel?: PriceLevelId) {
    if (!itemKey) {
      return Promise.resolve(null)
    }
    const { data } = await client.get<IPriceCalculation>(
      `/offer/price-calculation-info/${itemKey}`,
      {
        params: { priceLevel },
      }
    )
    return data
  }

  return { fetchPriceInfo }
}
