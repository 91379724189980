import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

type OptionsType = {
  readonly client?: AxiosInstance
  readonly baseURL?: string
  // readonly token?: string
  // readonly refreshToken?: string
  readonly switchUser?: string
  onAuthError?(): void
  setTokens?(token: string, refreshToken: string): void
  getTokens?(): { token?: string, refreshToken?: string }
}

export class Api {
  private readonly _client: AxiosInstance
  // private _token?: string
  // private _refreshToken?: string
  private _switchUser?: string
  private _refreshRequest: Promise<AxiosResponse> | null
  private _onAuthError?(): void
  private _setTokens?(token: string, refreshToken: string): void
  private _getTokens?(): { token?: string, refreshToken?: string }

  constructor(options: OptionsType = {}) {
    this._client = options.client ?? axios.create({ baseURL: options.baseURL })
    // this._token = options.token
    // this._refreshToken = options.refreshToken
    this._switchUser = options.switchUser
    this._refreshRequest = null
    this._onAuthError = options.onAuthError
    this._setTokens = options.setTokens
    this._getTokens = options.getTokens

    this._client.interceptors.request.use(
      (config) => {
        const token = this._getTokens?.()?.token
        if (!token) {
          return config
        }

        const newConfig = {
          headers: {},
          ...config,
        }

        newConfig.headers.Authorization = `Bearer ${token}`

        if (this._switchUser) {
          newConfig.headers['x-switch-user'] = this._switchUser
        }
        return newConfig
      },
      (e) => Promise.reject(e)
    )

    this._client.interceptors.response.use(
      (r) => r,
      async (
        error: AxiosError & { config: AxiosRequestConfig & { retry?: boolean } }
      ) => {
        const originalRequest: AxiosRequestConfig & { _isRetry?: boolean } =
          error.config

        if (error.response?.status === 401 && originalRequest?._isRetry !== true) {
          originalRequest._isRetry = true
          if (!this._refreshRequest) {
            this._refreshRequest = this._client.post<{token:string, refresh_token:string}>('/token/refresh', {
              refresh_token: this._getTokens?.()?.refreshToken,
            })
          }

          try {
            const { data } = await this._refreshRequest
            this._refreshRequest = null

            if(data?.token && data?.refresh_token) {
              this._setTokens?.(data?.token, data?.refresh_token)
            }

            return this._client.request(originalRequest)
          } catch (error) {
            if (/*axios.isAxiosError(error) &&*/ (error as any).response?.status === 401) {
              this._onAuthError?.()
            }
          }
        }
      
        throw error




        // debugger
        // if(error.response?.status === 401) {
        //   if (
        //     !this._refreshToken || error.config?.retry
        //   ) {
        //     this._onAuthError?.()
        //     throw error
        //   }

        //   if (!this._refreshRequest) {
        //     this._refreshRequest = this._client.post('/token/refresh', {
        //       refresh_token: this._refreshToken,
        //     })
        //   }
  
        //   const { data } = await this._refreshRequest
  
        //   this._token = data?.token
        //   this._refreshToken = data?.refresh_token
  
        //   const newRequest = {
        //     ...error.config,
        //     retry: true,
        //   }
  
        //   return this._client(newRequest)
        // }

        // throw error
      }
    )
  }

  get client() {
    return this._client
  }

  get isAuthenticated() {
    const token = this._getTokens?.()?.token
    return Boolean(token)
  }

  // async login({ login, password }) {
  //   const { data } = await this.client.post('/auth/login', { login, password })
  //   this.token = data.token
  //   this.refreshToken = data.refreshToken
  // }

  // logout() {
  //   this.token = null
  //   this.refreshToken = null
  // }
}
