import React from 'react'

import { MenuItemType } from '@monorepo/interfaces'
import { MenuItemsList } from './MenuItemsList'

type MenuPropsType = {
  items: MenuItemType[]
}

export const Menu: React.FC<MenuPropsType> = ({ items }) => (
  <div className="header__navbar-menu">
    <div className="main-menu">
      <MenuItemsList items={items} />
    </div>
  </div>
)
