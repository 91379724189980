import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentUser } from '@monorepo/core/user_module/useCurrentUser'
import { useApi } from '../api/useApi'
import { IAdvertisingCampaignSettings } from '../interfaces'

const QUERY_KEY = 'advertising_campaign_settings'

export type { Filter as CampaingsListFilter } from '../api/fetch_campaigns_list.api'

export function useCampaingSettings<TData = IAdvertisingCampaignSettings>(
  options?: UseQueryOptions<IAdvertisingCampaignSettings, AxiosError, TData>
) {
  const { fetchCampaignSettings } = useApi()
  const { data: user, isFetching: isUserFetching } = useCurrentUser()

  return useQuery(
    [QUERY_KEY, { user: user?.id }] as any,
    fetchCampaignSettings,
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      refetchInterval: 30 * 60 * 1000,
      enabled: !isUserFetching,
      keepPreviousData: true,
      ...options,
    }
  )
}
