/* eslint-disable no-template-curly-in-string */
import { CatalogType } from '@monorepo/interfaces'

const ilcats: CatalogType[] = []

type CategoryName =
  | 'Японские авто'
  | 'Европейские авто'
  | 'Американские авто'
  | 'Корейские авто'
  | 'Китайские авто'

const catalogsParts: (CatalogType & { category?: CategoryName })[] = [
  {
    id: 'seat',
    name: 'Seat',
    url:
      'https://seat.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/seat.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'skoda',
    name: 'Skoda',
    url:
      'https://skoda.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/skoda.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'volkswagen',
    name: 'Volkswagen',
    url:
      'https://volkswagen.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/vw.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'audi',
    name: 'Audi',
    url:
      'https://audi.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/audi.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'kia',
    name: 'Kia',
    url:
      'https://kia.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/kia.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'bmw',
    name: 'BMW',
    url:
      'https://bmw.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/bmw.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'bmw-i',
    name: 'BMW-I',
    url:
      'https://bmw-i.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/bmwi.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'bmw-moto',
    name: 'BMW Moto',
    url:
      'https://bmw-moto.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/bmw.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'rollsroyce',
    name: 'RollsRoyce',
    url:
      'https://rolls-royce.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/rolls_royce.png',
    isActive: false,
    category: 'Европейские авто',
  },
  {
    id: 'mini',
    name: 'Mini',
    url:
      'https://mini.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/mini.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'honda',
    name: 'Honda',
    url:
      'https://honda.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/honda.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'saab',
    name: 'Saab',
    url:
      'https://saab.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/saab.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'smart',
    name: 'Smart',
    url:
      'https://smart.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/smart.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'maybach',
    name: 'Maybach',
    url:
      'https://maybach.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/maybach.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'mercedes',
    name: 'Mercedes',
    url:
      'https://mercedes.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/merce.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'infiniti',
    name: 'Infiniti',
    url:
      'https://infiniti.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/infiniti.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'nissan',
    name: 'Nissan',
    url:
      'https://nissan.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/nissan.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'opel',
    name: 'Opel',
    url:
      'https://opel.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/opel.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'vauxhall',
    name: 'Vauxhall',
    url:
      'https://vauxhall.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/vauxhall.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'lexus',
    name: 'Lexus',
    url:
      'https://lexus.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/lexus.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'toyota',
    name: 'Toyota',
    url:
      'https://toyota.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/toyota.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'hyundai',
    name: 'Hyundai',
    url:
      'https://hyundai.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/hyundai.png',
    isActive: true,
    category: 'Корейские авто',
  },
  {
    id: 'ford',
    name: 'Ford',
    url:
      'https://ford.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/ford.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'chevrolet',
    name: 'Chevrolet',
    url:
      'https://chevrolet.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/chevrolet.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'subaru',
    name: 'Subaru',
    url:
      'https://subaru.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/subaru.png',
    isActive: true,
    category: 'Японские авто',
  },
  {
    id: 'fiat',
    name: 'Fiat',
    url:
      'https://fiat.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/fiat.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'fiat-pro',
    name: 'Fiat Pro',
    url:
      'https://fiat-pro.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/fiat-pro.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'alfa-romeo',
    name: 'Alfa Romeo',
    url:
      'https://alfa-romeo.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/alfa_romeo.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'lancia',
    name: 'Lancia',
    url:
      'https://lancia.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/lancia.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'abarth',
    name: 'Abarth',
    url:
      'https://abarth.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/abarth.png',
    isActive: true,
    category: 'Европейские авто',
  },
  {
    id: 'cadillac',
    name: 'Cadillac',
    url:
      'https://cadillac.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/cadillac.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'buick',
    name: 'Buick',
    url:
      'https://buick.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/buick.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'hummer',
    name: 'Hummer',
    url:
      'https://hummer.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;region:rdw}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/hummer.png',
    isActive: true,
    category: 'Американские авто',
  },
  {
    id: 'tecdoc',
    name: 'TecDoc',
    // url: 'http://zap.aes777.ru/tecdoc',
    url:
      'https://aftermarket.catalogs-parts.com/#{client:${clientId};page:models;lang:ru;catalog:pc}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/aftermarket.png',
    isActive: true,
  },
  {
    id: 'to',
    name: 'Каталог ТО',
    // ul: 'http://zap.aes777.ru/to',
    url:
      'https://to.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/to.png',
    isActive: true,
  },
  {
    id: 'lamps',
    name: 'Автолампы',
    // ul: 'http://zap.aes777.ru/lamps',
    url: 'https://lamps.catalogs-parts.com/#{client:${clientId};lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/lamps.png',
    isActive: true,
  },
  {
    id: 'batteries',
    name: 'Аккумуляторы',
    // ul: 'http://zap.aes777.ru/batteries',
    url:
      'https://batteries.catalogs-parts.com/#{client:${clientId};page:batteries;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/batteries.png',
    isActive: true,
  },
  {
    id: 'tires',
    name: 'Шины',
    // ul: 'http://zap.aes777.ru/tires',
    url:
      'https://tires.catalogs-parts.com/#{client:${clientId};page:tires;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/tires.png',
    isActive: true,
  },
  {
    id: 'diski',
    name: 'Диски',
    // ul: 'http://zap.aes777.ru/diski',
    url:
      'https://wheels.catalogs-parts.com/#{client:${clientId};page:wheels;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/wheels.png',
    isActive: true,
  },
  {
    id: 'oils',
    name: 'Масла',
    // ul: 'http://zap.aes777.ru/oils',
    url:
      'https://oils.catalogs-parts.com/#{client:${clientId};page:oils;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/oils.png',
    isActive: true,
  },
  {
    id: 'bolty-gayki',
    name: 'Болты, Гайки',
    // ul: 'http://zap.aes777.ru/bolty-gayki',
    url:
      'https://bolts.catalogs-parts.com/#{client:${clientId};page:bolts;lang:ru;group:0}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/bolts.png',
    isActive: true,
  },
  {
    id: 'caps',
    name: 'Колпаки',
    // ul: 'http://zap.aes777.ru/caps',
    url:
      'https://caps.catalogs-parts.com/#{client:${clientId};page:caps;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/caps.png',
    isActive: true,
  },
  {
    id: 'aksessuary',
    name: 'Аксессуары',
    // ul: 'http://zap.aes777.ru/aksessuary',
    url:
      'https://accessories.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}',
    image: 'https://img.catalogs-parts.com/images/cp_brands/accessories.png',
    isActive: true,
  },
]

export const initialData = {
  ilcats,
  catalogsParts,
}
