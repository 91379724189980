import React from 'react'

import { ScrollToTop } from 'App/Components/ScrollToTop'
import { AdvertisingProvider } from '@monorepo/advertising_module'
import { Announcements } from './components/Announcements'
import { Header } from './Header'
import Footer from './Footer'

type PropsType = {
  topSpace?: boolean
  bottomSpace?: boolean
}

export const Layout: React.FC<PropsType> = ({
  children,
  topSpace = false,
  bottomSpace = false,
}) => (
  <div className="site">
    <Header />
    <div className="site__body">
      {topSpace && (
        <div className="block-space block-space--layout--after-header" />
      )}
      <AdvertisingProvider>
        {children}
        <Announcements />
      </AdvertisingProvider>
      {bottomSpace && (
        <div className="block-space block-space--layout--before-footer" />
      )}
    </div>
    <Footer />
    <ScrollToTop />
  </div>
)
