import { useMemo } from 'react'
// import { useDispatch } from 'react-redux'
// import axios /*AxiosError*/ from 'axios'
// import { AxiosInstance } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
// import { pickBy } from 'lodash'

import { Api } from './api.class'
// import jwt /*TokenExpiredError*/ from 'jsonwebtoken'

import { apiURL } from '@monorepo/core/config'
import {
  tokenSelector,
  refreshTokenSelector,
  switchUserSelector,
  logoutAction,
  setTokensAction,
  // setTokensAction,
} from '@monorepo/core/features/auth'

// import { useErrorHandler } from './useErrorHandler'

// import {
//   setTokensAction,
//   clearTokensAction,
// } from '@monorepo/core/features/auth/actions'

// type PayloadType = {
//   exp: number
//   iat: number
//   roles: string[]
//   username: string
// }

// type RefreshTokenResponseType = {
//   token: string
//   refresh_token: string
// }

// ;(<Window & typeof globalThis & { jwt: any }>window).jwt = jwt
// ;(<typeof window & { jwt: any }>window).jwt = jwt
// function isExpiredToken(token: string) {
//   const payload = jwt.decode(token)

//   const expiredAt = get(payload, 'exp', 0)
//   return Date.now() / 1000 > expiredAt
// }

interface IUseClientOptions {
  baseURL?: string
}

export const useClient = (options?: IUseClientOptions) => {
  const token = useSelector(tokenSelector) || undefined
  const refreshToken = useSelector(refreshTokenSelector) || undefined
  const switchUser = useSelector(switchUserSelector)
  const dispatch = useDispatch()

  const client = useMemo(() => {
    return new Api({
      baseURL: options?.baseURL ?? apiURL,
      // token: token ?? undefined,
      // refreshToken: refreshToken ?? undefined,
      switchUser: switchUser ?? undefined,
      setTokens(token: string, refreshToken: string) {
        dispatch(setTokensAction({ token, refreshToken }))
      },
      getTokens() {
        return {
          token,
          refreshToken
        }
      },
      onAuthError() {
        dispatch(logoutAction())
      },
    })
    // return Object.assign(api.getClient(), {
    //   isAuthenticated: api.isAuthenticated,
    // })
  }, [dispatch, options, refreshToken, switchUser, token])
  // const client = useMemo(() => {
  //   return new Api({
  //     baseURL: options?.baseURL ?? apiURL,
  //     token: token ?? undefined,
  //     refreshToken: refreshToken ?? undefined,
  //   }).getClient()
  // }, [options, refreshToken, token])

  // ;(<typeof window & { token: any }>window).token = token

  // const headers = pickBy({
  //   // 'Content-Type': 'application/json',
  //   // 'X-Requested-With': 'XMLHttpRequest',
  //   'X-Switch-User': switchUser,
  //   Authorization: token && `Bearer ${token}`,
  // })

  // // const [isRefresh, setRefresh] = useState(false)

  // const client = axios.create({
  //   baseURL: apiURL,
  //   responseType: 'json',
  //   headers,
  // })

  // useErrorHandler(client)

  return client
}
