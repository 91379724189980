import React, { useContext } from 'react'
import invariant from 'invariant'
import sample from 'lodash/sample'

import { useCampaingSettings, useCampaingsList } from '../hooks'
import { AdvertisingContext, IAdvertisingContext } from './advertising.context'
import { useCompState } from './hooks/useCompState'

type AdvertisingProviderProps = {
  // children?: ((props: IAdvertisingContext) => React.ReactNode) | React.ReactNode
  children?: React.ReactNode
}

export const AdvertisingProvider: React.FC<AdvertisingProviderProps> = ({
  children,
}) => {
  const { data: settings } = useCampaingSettings()

  const [isShowToast, hideToastHandler] = useCompState('advertising_toast', {
    enabled: settings?.isToastActive ?? false,
    period: settings?.toastShowTime ?? 0,
    initialDelay: settings?.initialDelay ?? 0.25,
  })

  const [isShowModal, hideModalHandler] = useCompState('advertising_modal', {
    enabled: settings?.isModalActive ?? false,
    period: settings?.modalShowTime ?? 0,
    initialDelay: settings?.initialDelay ?? 0.25,
  })

  const { data: toastActions } = useCampaingsList(undefined, {
    enabled: isShowToast,
    // select: (items) => (isShowToast ? items : undefined),
  })

  const { data: modalActions } = useCampaingsList(undefined, {
    enabled: isShowModal,
    // select: (items) => (isShowModal ? sample(items) : undefined),
  })

  const context: IAdvertisingContext = {
    toastActions,
    hideToastHandler,
    isShowToast,
    modalAction: isShowModal ? sample(modalActions) : undefined,
    hideModalHandler,
    isShowModal,
    settings,
  }

  return (
    <AdvertisingContext.Provider value={context}>
      {children}
    </AdvertisingContext.Provider>
  )
}

export function useAdvertisingSettings() {
  const context = useContext(AdvertisingContext)

  invariant(context, 'Add [AdvertisingProvider] to your app!')

  return context
}
