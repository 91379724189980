import WebFont from 'webfontloader'

import '@monorepo/core/utils/jquery-loader'

import 'App/Resources/styles/style.scss'

WebFont.load({
  google: {
    families: ['Roboto:400,400italic,500,500italic,700,700italic'],
  },
})

export * from './Page'
export * from './Layout'
