import React from 'react'

import { MenuItemType } from '@monorepo/interfaces'
import { Toolbar } from './Toolbar'
import { Logo } from './Logo'
import { Navbar } from './Navbar'
import { Search } from './Search'
import { Indicators } from './Indicators'

type MainHeaderPropsType = {
  menu: MenuItemType[]
  logoImage: string
}

export const MainHeader: React.FC<MainHeaderPropsType> = ({
  menu,
  logoImage,
}) => (
  <header className="site__header sticky-top" style={{ top: -35 }}>
    <div className="header">
      <div className="header__megamenu-area megamenu-area" />
      <div className="header__topbar-classic-bg" />
      <Toolbar />
      <Navbar menu={menu} />
      <Logo logoImage={logoImage} />
      <Search />
      <Indicators />
    </div>
  </header>
)
