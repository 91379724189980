import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { get, toString } from 'lodash'

import {
  userSelector,
  updateProfileAction,
  registerUserAction,
} from '@monorepo/core/features/user'

import {
  authenticateAction,
  logoutAction,
  stateSelector as authStateSelector,
  tokenSelector,
} from '@monorepo/core/features/auth'

function getErrors(error: Error) {
  return get(error, 'error.response.data.errors', [
    { message: 'Произошла неизвестная ошибка' },
  ]).map((error: any) => get(error, 'message', toString(error)))
}

export function useUser() {
  const { data, loading } = useSelector(userSelector)
  const { error } = useSelector(authStateSelector)
  const token = useSelector(tokenSelector)

  const user = useMemo(() => data, [data])
  const dispatch = useDispatch()

  const loginHandler = useCallback(
    bindActionCreators(authenticateAction, dispatch),
    []
  )
  const logoutHandler = useCallback(
    bindActionCreators(logoutAction, dispatch),
    []
  )
  const updateProfileHandler = useCallback(
    // bindActionCreators<VoidFunction, any>(updateProfileAction, dispatch),
    bindActionCreators(updateProfileAction, dispatch),
    []
  )
  // const registerUserHandler = useCallback(
  //   bindActionCreators<VoidFunction, any>(registerUserAction, dispatch),
  //   []
  // )

  const registerUserHandler = useCallback(
    (values) =>
      new Promise(async (resolve, reject) => {
        try {
          await dispatch(registerUserAction(values))
          resolve(undefined)
        } catch (error) {
          reject(getErrors(error as Error))
        }
      }),
    [dispatch]
  )

  return {
    user,
    // isReady: true,
    isReady: useMemo(() => !token || (!!token && !user.isGuest), [
      token,
      user.isGuest,
    ]),
    profile: user.profile,
    loading,
    error,
    loginHandler,
    logoutHandler,
    updateProfileHandler,
    registerUserHandler,
  }
}
