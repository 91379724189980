// import { ilcats } from '@monorepo/core/config'
import { MenuType, MenuCollectionType } from '@monorepo/interfaces'

export const main: MenuType = [
  // {
  //   name: 'Главная',
  //   url: '/',
  // },
  {
    name: 'Каталоги',
    url: '/original-catalogs',
    // menu: [
    //   {
    //     name: 'Оригинальные каталоги подбора',
    //     url: `https://www.ilcats.ru/?clid=${ilcats.clientId}`,
    //     external: true,
    //   },
    //   {
    //     name: 'Запчасти для ТО',
    //     url: `https://www.ilcats.ru/maintenance&clid=${ilcats.clientId}`,
    //     external: true,
    //   },
    // ],
  },
  {
    name: 'Автосервис',
    url: 'http://aes777.ru',
  },
  {
    name: 'Полезное',
    url: '/about-us',
    menu: [
      {
        name: 'О нас',
        url: '/about-us',
      },
      {
        name: 'Контакты',
        url: '/contacts',
      },
      {
        name: 'Статьи',
        url: '/blog',
      },
      {
        name: 'Доставка',
        url: '/pages/dostavka',
      },
      {
        name: 'Как оплатить заказ',
        url: '/pages/kak_oplatit_zazkaz',
      },
      {
        name: 'Договор оферты',
        url: '/pages/oferta',
      },
      {
        name: 'Обмен и возврат товара',
        url: '/pages/obmen_vozvrat_tovara',
      },
      {
        name: 'Политика конфиденциальности',
        url: '/pages/privacy-policy',
      },
      {
        name: 'Обработкой персональных данных',
        url: '/pages/processing-personal-data',
      },
      {
        name: 'Использование информации',
        url: '/pages/restrictions-of-use',
      },
      {
        name: 'Пользовательское соглашение',
        url: '/pages/terms-of-use',
      },
    ],
  },
  // {
  //   name: 'Товары',
  //   url: '/goods',
  // },
  {
    name: 'Кабинет',
    url: '/cabinet',
    menu: [
      {
        name: 'Перейти в кабинет',
        url: '/cabinet',
      },
      // {
      //   name: 'Перейти в админпанель',
      //   url: '/admin',
      //   roles: 'admin',
      // },
      {
        name: 'Баланс',
        url: '/balance',
      },
      {
        name: 'Корзина',
        url: '/cart',
      },
      {
        name: 'Заказы',
        url: '/orders',
      },
      // {
      //   name: '-Скачать прайс-лист',
      // },
      {
        name: 'Гараж',
        url: '/garage',
      },
      {
        name: 'Админпанель',
        url: '/admin',
        external: true,
        roles: ['admin'],
      },
      // {
      //   name: 'Выход',
      //   url: '/logout',
      // },
    ],
  },
  {
    name: 'Контакты',
    url: '/contacts',
  },
]

const userButton: MenuType = [
  {
    name: 'Личный кабинет',
    url: '/cabinet',
  },
  {
    name: 'Гараж',
    url: '/garage',
  },
  {
    name: 'Редактировать профиль',
    url: '/profile',
  },
  {
    name: 'Заказы',
    url: '/orders',
  },
  {
    name: 'Админпанель',
    url: '/admin',
    roles: ['admin'],
    external: true,
  },
  // {
  //   name: 'Адреса доставки',
  //   url: '/delivery',
  // },
]

const cabinet: MenuType = [
  {
    name: 'Панель',
    url: '/cabinet',
  },
  {
    name: 'Гараж',
    url: '/garage',
  },
  {
    name: 'Редактировать профиль',
    url: '/profile',
  },
  // {
  //   name: 'Адреса доставки',
  //   url: '/account-addresses',
  //   disabled: true,
  // },
  {
    name: 'Заказы',
    url: '/orders',
  },
  {
    name: 'Взаиморасчеты',
    url: '/balance',
  },
  {
    name: 'Сменить пароль',
    url: '/account/change-password',
  },
  {
    name: 'delimiter',
    url: '',
    roles: ['admin'],
  },
  {
    name: 'Админ. панель',
    url: '/admin',
    roles: ['admin'],
    external: true,
  },
  { name: 'delimiter', url: '' },
  {
    name: 'Выйти',
    url: '/account/logout',
  },
]

export const initialState: MenuCollectionType = {
  main,
  cabinet,
  userButton,
}
