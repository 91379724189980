import React from 'react'

import { App } from '@monorepo/hooks'
import { Link } from 'App/Components'

type LogoProps = {
  logoImage: string
}

const logoStyle = { maxWidth: 200, maxHeight: 70 }

export const Logo: React.FC<LogoProps> = ({ logoImage }) => {
  const { company } = App.useCompany()

  return (
    <div className="header__logo">
      <Link to="/" className="logo">
        <div className="logo__slogan">{company.name}</div>
        <div className="logo__image">
          <img src={logoImage} style={logoStyle} alt="" />
        </div>
      </Link>
    </div>
  )
}
