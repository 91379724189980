import React from 'react'
import { isFunction, uniqueId } from 'lodash'
import cn from 'classnames'
import {
  Button,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import ConditionalWrap from 'conditional-wrap'

import { IBrand } from '@monorepo/interfaces'
import { useComponentVisible, Brand } from '@monorepo/hooks'
import { Image } from '@monorepo/components/common'
import { parseUrl } from '@monorepo/core/helpers'
import { Link } from 'App/Components'

// import style from './style.module.scss'

type BrandInfoProps = React.HTMLAttributes<HTMLButtonElement> & {
  brand: IBrand
  tooltip?: React.ReactNode
}

export const BrandInfoModal: React.FC<BrandInfoProps> = ({
  brand,
  tooltip,
  className,
  children,
  ...props
}) => {
  const {
    isComponentVisible,
    showComponentHandler,
    hideComponentHandler,
  } = useComponentVisible(false)

  const { data: brandInfo, isFetching } = Brand.useInfo(
    brand,
    isComponentVisible
  )

  const siteUrl = parseUrl(brandInfo?.site)

  return (
    <>
      <ConditionalWrap
        condition={tooltip && !isComponentVisible}
        wrap={(children: any) => (
          <OverlayTrigger
            overlay={<Tooltip id={uniqueId('tooltip-')}>{tooltip}</Tooltip>}
          >
            {children}
          </OverlayTrigger>
        )}
      >
        <Button
          className={cn(className, 'text-decoration-none')}
          variant="link"
          onClick={showComponentHandler}
          {...props}
        >
          {isFunction(children) ? children({ brand: brandInfo }) : children}
        </Button>
      </ConditionalWrap>

      <Modal
        show={isComponentVisible}
        onHide={hideComponentHandler}
        size={brandInfo?.description ? 'lg' : undefined}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>{brand.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isFetching ? (
            <span className="d-flex">
              <Spinner animation="border" variant="primary" />
              <span className="px-2">
                Загрузка данных о <strong>«{brand.name}»</strong>...
              </span>
            </span>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  {brandInfo?.country && (
                    <>
                      <span className="mr-2">Страна:</span>
                      <em className="text-capitalize">{brandInfo.country}</em>
                    </>
                  )}
                </div>
                <div>
                  {siteUrl && (
                    <>
                      <span className="mr-2">Сайт:</span>
                      <Link to={siteUrl.toString()}>
                        <em className="text-lowercase">{siteUrl.hostname}</em>
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <p className="mt-4 text-justify">
                {brandInfo?.thumbs && (
                  <Image
                    className="float-left mt-1 mr-2 mb-1"
                    src={brandInfo.thumbs}
                    loader={false}
                    lazy={false}
                  />
                )}
                {brandInfo?.description ? (
                  <>{brandInfo.description}</>
                ) : (
                  <>
                    Информация о <strong>«{brand.name}»</strong> пока
                    отсутствует
                  </>
                )}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideComponentHandler}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
