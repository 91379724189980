import React, { useCallback } from 'react'
import cn from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { User } from '@monorepo/hooks'
import { Link } from 'App/Components'

type PropsType = {
  onSuccess?(): void
  onError?(): void
}

export const LoginForm: React.FC<PropsType> = ({ onSuccess, onError }) => {
  const {
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  } = User.useSigninForm()

  const onSubmit = useCallback(
    async (credentails, { setFieldError }) => {
      try {
        await submitHandler(credentails)
        onSuccess && onSuccess()
      } catch (error) {
        setFieldError('username', error.message)
        onError && onError()
      }
    },
    [onError, onSuccess, submitHandler]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className="account-menu__form">
          <div className="account-menu__form-title">Войти в аккаунт</div>
          <div className="form-group">
            <label htmlFor="header-signin-email" className="sr-only">
              Email адрес
            </label>
            <Field
              id="header-signin-email"
              // innerRef={focusRef}
              type="email"
              className={cn('form-control', 'form-control-sm', {
                'is-invalid': errors.username && touched.username,
              })}
              placeholder="Email адрес"
              name="username"
            />
            <ErrorMessage name="username">
              {msg => <div className="invalid-feedback">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group">
            <label htmlFor="header-signin-password" className="sr-only">
              Пароль
            </label>
            <div
              className={cn('account-menu__form-forgot', {
                'is-invalid': errors.password && touched.password,
              })}
            >
              <Field
                id="header-signin-password"
                type="password"
                className={cn('form-control', 'form-control-sm', {
                  'is-invalid': errors.password && touched.password,
                })}
                placeholder="Пароль"
                name="password"
              />
              <Link
                to="/account/reset-password"
                className="account-menu__form-forgot-link"
              >
                Забыли?
              </Link>
            </div>
            <ErrorMessage name="password">
              {msg => <div className="invalid-feedback">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="form-group account-menu__form-button">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={isSubmitting}
            >
              Войти&nbsp;
              <FontAwesomeIcon
                icon={isSubmitting ? faCircleNotch : faArrowRight}
                spin={isSubmitting}
                fixedWidth
              />
            </button>
          </div>
          <div className="account-menu__form-link">
            <Link to="/account/registration">Зарегистрироваться</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}
