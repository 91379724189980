import { AxiosInstance } from 'axios'
import { IAdvertisingCampaign } from '../interfaces'

export const fetchCampaigBySlug = (client: AxiosInstance) => async (
  slug: string
) => {
  try {
    const { data } = await client.get<IAdvertisingCampaign | null>(
      `/actions_campaign/${slug}`
    )
    return data
  } catch (error) {
    throw new Error((error as any).message)
  }
}
