import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { delay } from 'lodash'
import gravatar from 'gravatar'
import 'csshake'

import { User, useComponentVisible } from '@monorepo/hooks'
import { LoginForm } from './LoginForm'
import { Menu } from './Menu'

type PropsType = {
  //
}

export const UserButton: React.FC<PropsType> = props => {
  const { user, loading } = User.useUser()
  const [shake, setShake] = useState<boolean>(false)

  const loginErrorHandler = useCallback(() => {
    setShake(true)
    delay(setShake, 500, false)
  }, [])

  const {
    componentRef,
    isComponentVisible,
    toggleComponentHandler,
    hideComponentHandler,
  } = useComponentVisible(false)

  return (
    <div
      className={cn('indicator', 'indicator--trigger--click', {
        'indicator--open': isComponentVisible,
      })}
    >
      <button onClick={toggleComponentHandler} className="indicator__button">
        <span className="indicator__icon">
          {loading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin={loading} size="2x" />
          ) : user.isGuest ? (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32}>
              <path
                d="M16,18C9.4,18,4,23.4,4,30H2c0-6.2,4-11.5,9.6-13.3C9.4,15.3,8,12.8,8,10c0-4.4,3.6-8,8-8s8,3.6,8,8c0,2.8-1.5,5.3-3.6,6.7
C26,18.5,30,23.8,30,30h-2C28,23.4,22.6,18,16,18z M22,10c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6S22,13.3,22,10z"
              />
            </svg>
          ) : (
            <img
              src={gravatar.url(user.profile.email!, { default: 'mp' })}
              className="rounded-circle"
              width={32}
              height={32}
              alt=""
            />
          )}
        </span>
        <span className="indicator__title">
          {user.isGuest ? (
            'Регистрация'
          ) : (
            <>
              {user.profile.firstName}, [ID: {user.id}]
            </>
          )}
        </span>
        <span className="indicator__value">
          {user.isGuest ? 'Вход' : 'Кабинет'}
        </span>
      </button>
      <div
        className={cn('indicator__content', {
          'shake-horizontal': shake,
          'shake-constant': shake,
        })}
        ref={componentRef}
      >
        <div className="account-menu">
          {user.isGuest ? (
            <LoginForm
              onSuccess={hideComponentHandler}
              onError={loginErrorHandler}
            />
          ) : (
            <Menu user={user} />
          )}
        </div>
      </div>
    </div>
  )
}
