import { useQuery } from 'react-query'

import { PriceLevelId, useApi } from './useApi'

import { IOffer } from '@monorepo/interfaces'

export function useInfo(itemKey: IOffer['itemKey'], priceLevel?: PriceLevelId) {
  const { fetchPriceInfo } = useApi()

  return useQuery(
    ['fetchPriceInfo', itemKey, priceLevel],
    () => fetchPriceInfo(itemKey, priceLevel),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  )
}
