import React from 'react'

import { SearchField } from 'App/Components'
import { useComponentVisible } from '@monorepo/hooks'

type SearchPropsType = {}

export const Search: React.FC<SearchPropsType> = () => {
  const {
    isComponentVisible,
    showComponentHandler,
    hideComponentHandler,
    componentRef,
  } = useComponentVisible(false)

  return (
    <div className="header__search">
      <div className="search">
        <SearchField
          ref={componentRef}
          show={isComponentVisible}
          onShow={showComponentHandler}
          onHide={hideComponentHandler}
        />
      </div>
    </div>
  )
}
