import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import { chain } from 'lodash'

import { MenuItemType, MenuType } from '@monorepo/interfaces'
import { useComponentVisible, Catalog } from '@monorepo/hooks'
import { MenuItem } from './MenuItem'
import { SubMenu } from './SubMenu'

type PropsType = {
  //
}

export const MenuButton: React.FC<PropsType> = () => {
  const {
    isComponentVisible,
    componentRef,
    toggleComponentHandler,
  } = useComponentVisible(false)

  const [current, setCurrent] = useState<MenuItemType | null>(null)

  const hoverHandler = useCallback((item: MenuItemType) => {
    setCurrent(item)
  }, [])

  const {
    carCatalogs,
    commonCatalogs,
  } = Catalog.CatalogsParts.useCatalogsList()

  const catalogs = useMemo(
    () =>
      chain(carCatalogs)
        .groupBy('category')
        .map((items, category) => ({
          name: category,
          menu: chain(items)
            .map(catalog => ({
              name: catalog.name,
              url: catalog.mountPoint,
              icon: catalog.image,
            }))
            .sortBy('name')
            .value(),
        }))
        .sortBy('name')
        .concat({
          name: 'Общие каталоги',
          menu: commonCatalogs.map(catalog => ({
            name: catalog.name,
            url: catalog.mountPoint,
            icon: catalog.image,
          })),
        })
        .value() as MenuType,
    [carCatalogs, commonCatalogs]
  )

  return (
    <div className="header__navbar-departments">
      <div
        className={cn('departments', {
          'departments--open': isComponentVisible,
        })}
      >
        <button
          className="departments__button"
          type="button"
          onClick={toggleComponentHandler}
        >
          <span className="departments__button-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="12px">
              <path d="M0,7L0,5L16,5L16,7L0,7ZM0,0L16,0L16,2L0,2L0,0ZM12,12L0,12L0,10L12,10L12,12Z" />
            </svg>
          </span>
          <span className="departments__button-title">Каталоги</span>
          <span className="departments__button-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="6px">
              <path d="M0.2,0.4c0.4-0.4,1-0.5,1.4-0.1l2.9,3l2.9-3c0.4-0.4,1.1-0.4,1.4,0.1c0.3,0.4,0.3,0.9-0.1,1.3L4.5,6L0.3,1.6C-0.1,1.3-0.1,0.7,0.2,0.4z" />
            </svg>
          </span>
        </button>
        <div className="departments__menu" ref={componentRef}>
          <div className="departments__arrow" />
          <div className="departments__body">
            <ul className="departments__list">
              <li className="departments__list-padding" role="presentation" />
              {catalogs.map((item, idx) => (
                <MenuItem
                  key={idx}
                  item={item}
                  onMouseOver={hoverHandler.bind(null, item)}
                  className={cn({
                    'departments__item--hover': current === item,
                  })}
                />
              ))}
              <li className="departments__list-padding" role="presentation" />
            </ul>
            <div className="departments__menu-container">
              {current && current.menu && (
                <SubMenu
                  items={current.menu}
                  cols={4}
                  renderItem={item => (
                    <>
                      <div className="text-center text-uppercase">
                        {item.icon && <img src={item.icon as string} alt="" />}
                      </div>

                      <div className="text-center text-uppercase mt-2 mb-2 bg-gradient-dark">
                        {item.name}
                      </div>
                    </>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
