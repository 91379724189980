import { AxiosInstance } from 'axios'
import { IAdvertisingCampaign } from '../interfaces'

export type Filter = {
  page?: number
  perPage?: number
  pagination?: boolean
}

export const fetchCampaignsList = (client: AxiosInstance) => async (
  filter?: Filter
) => {
  try {
    const { data } = await client.get<IAdvertisingCampaign[]>(
      '/actions_campaign',
      {
        params: filter,
      }
    )
    return data
  } catch (error) {
    throw new Error((error as any).message)
  }
}
