import React from 'react'

import { Link } from 'App/Components'

type ErrorLayoutProps = {
  code: number | string
  title: React.ReactNode
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  code,
  title,
  children,
}) => (
  <div className="block">
    <div className="container">
      <div className="not-found">
        <div className="not-found__404">Упс! Ошибка {code}</div>
        <div className="not-found__content">
          <h1 className="not-found__title">{title}</h1>
          <div className="not-found__text">{children}</div>
          {/* <form className="not-found__search">
            <input
              type="text"
              className="not-found__search-input form-control"
              placeholder="Search Query..."
            />{' '}
            <button
              type="submit"
              className="not-found__search-button btn btn-primary"
            >
              Search
            </button>
          </form> */}
          {/* <p className="not-found__text">
            Or go to the home page to start over.
          </p> */}
          <Link className="btn btn-secondary btn-sm" to="/">
            Перейти на главную
          </Link>
        </div>
      </div>
    </div>
  </div>
)
