import React from 'react'
import { Image, ImageProps } from '@monorepo/components/common'

import imagePlaceholder from 'App/Resources/images/placeholder-300x300.png'

type ImagePlaceholderPropsType = ImageProps & {
  //
}

export const ImagePlaceholder: React.FC<ImagePlaceholderPropsType> = props => (
  <Image src={imagePlaceholder} alt="" {...props} />
)
