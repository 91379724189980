import React from 'react'

import { Search } from './Search'
import { Login } from './Login'
import { Cart } from './Cart'

type IndicatorsPropsType = {
  onShowSearch?(): void
}

export const Indicators: React.FC<IndicatorsPropsType> = ({ onShowSearch }) => (
  <div className="mobile-header__indicators">
    <Search onShow={onShowSearch} />
    <Login />
    <Cart />
  </div>
)
