import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SocialLinkType } from '@monorepo/interfaces'
import { Link } from 'App/Components'
import { getNetworkIcon } from './getNetworkIcon'

type LinkItemPropsType = {
  link: SocialLinkType
}

export const LinkItem: React.FC<LinkItemPropsType> = ({ link }) => (
  <li
    key={link.name}
    className={`social-links__item social-links__item--${link.name.toLocaleLowerCase()}`}
  >
    <Link to={link.url} external>
      <FontAwesomeIcon
        className="widget-social__icon"
        icon={getNetworkIcon(link)}
      />
    </Link>
  </li>
)
