import React from 'react'

import { MenuItemType } from '@monorepo/interfaces'
import { MenuItem } from './MenuItem'

type LinksListPropsType = {
  items: MenuItemType[]
}

export const LinksList: React.FC<LinksListPropsType> = ({ items }) => (
  <ul className="mobile-menu__links">
    {items.map((item, idx) => (
      <MenuItem key={idx} item={item} />
    ))}
  </ul>
)
