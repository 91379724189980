import React from 'react'

import { formatDeliveryTime } from '@monorepo/core/helpers'

type DeliveryTimeProps = {
  value: number
  isStock?: boolean
  emptyValue?: number | string
}

export const FormattedDeliveryTime: React.FC<DeliveryTimeProps> = ({
  value,
  isStock,
  emptyValue,
}) => (
  <>
    {value === 0 && isStock
      ? 'На складе'
      : formatDeliveryTime(value, emptyValue)}
  </>
)
