import React from 'react'
import cn from 'classnames'
import { useField } from 'formik'

type CheckboxPropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string
}

export const Checkbox: React.FC<CheckboxPropsType> = ({
  className,
  ...props
}) => {
  const [field /*meta*/ /*helpers*/, ,] = useField(props)

  return (
    <span className="input-check__body">
      <input
        className={cn('input-check__input', className)}
        type="checkbox"
        {...field}
        checked={field.value}
      />
      <span className="input-check__box"></span>
      <span className="input-check__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="9px" height="7px">
          <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z" />
        </svg>
      </span>
    </span>
  )
}
