import React from 'react'

import { MenuItemType } from '@monorepo/interfaces'
import { useComponentVisible } from '@monorepo/hooks'
import { Link } from 'App/Components'
import { Indicators } from './Indicators'
import { MobileMenu } from './MobileMenu'
import { SearchField } from 'App/Components'

type MobileHeaderPropsType = {
  logoImage: string
  menu: MenuItemType[]
}

const logoStyle = { maxWidth: 130 }

export const MobileHeader: React.FC<MobileHeaderPropsType> = ({
  logoImage,
  menu,
}) => {
  const {
    isComponentVisible: showSearch,
    componentRef: searchRef,
    showComponentHandler: showSearchHandler,
    hideComponentHandler: hideSearchHandler,
  } = useComponentVisible(false)
  const {
    isComponentVisible: showMenu,
    componentRef: menuRef,
    toggleComponentHandler: toggleMenuHandler,
    hideComponentHandler: hideMenuHandler,
  } = useComponentVisible(false)
  return (
    <>
      <header className="site__mobile-header sticky-top">
        <div className="mobile-header">
          <div className="container">
            <div className="mobile-header__body">
              <button
                className="mobile-header__menu-button"
                type="button"
                onClick={toggleMenuHandler}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="14px"
                >
                  <path d="M-0,8L-0,6L18,6L18,8L-0,8ZM-0,-0L18,-0L18,2L-0,2L-0,-0ZM14,14L-0,14L-0,12L14,12L14,14Z" />
                </svg>
              </button>
              <Link className="mobile-header__logo" to="/">
                <img src={logoImage} style={logoStyle} alt="" />
              </Link>
              <SearchField
                ref={searchRef}
                show={showSearch}
                onHide={hideSearchHandler}
                onShow={showSearchHandler}
                mobile
              />
              <Indicators onShowSearch={showSearchHandler} />
            </div>
          </div>
        </div>
      </header>
      <MobileMenu
        menu={menu}
        ref={menuRef}
        show={showMenu}
        onHide={hideMenuHandler}
      />
    </>
  )
}
