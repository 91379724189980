import { useCallback, useEffect, useState } from 'react'
import { getPrevTimeMs, setPrevTimeMs, toMs } from './utils'

export type Options = {
  enabled?: boolean
  initialDelay?: number
  period?: number
}

export function useCompState(
  stateName: string,
  { enabled = false, initialDelay = 0.25, period = 30 }: Options
): [boolean, () => void] {
  const periodMs = toMs(period)
  const initialDelayMs = toMs(initialDelay)

  const [isShow, setShow] = useState(false)

  const [showAt, setShowAt] = useState<number>(getPrevTimeMs(stateName))

  const updateShowAt = useCallback(() => {
    const next = Date.now() + periodMs
    setShowAt(next)
    setPrevTimeMs(stateName, next)
  }, [periodMs, stateName])

  const hideHandler = useCallback(() => {
    setShow(false)
    updateShowAt()
  }, [updateShowAt])

  const showHandler = useCallback(() => {
    setShow(true)
    updateShowAt()
  }, [updateShowAt])

  useEffect(() => {
    if (!enabled) {
      return
    }
    if (typeof showAt === 'undefined') {
      setShowAt(Date.now() + initialDelayMs)
    } else {
      const delay = Math.max(showAt - Date.now(), initialDelayMs)
      const timerId = setTimeout(showHandler, delay)
      return clearInterval.bind(null, timerId)
    }
  }, [enabled, initialDelayMs, showAt, showHandler])

  return [isShow, hideHandler]
}
