import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => [
  {
    path: '/cabinet',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ Dashboard }) => Dashboard)
    ),
    exact: true,
  },
  {
    path: '/garage',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ Garage }) => Garage)
    ),
    exact: true,
  },
  {
    path: '/profile',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ Profile }) => Profile)
    ),
    exact: true,
  },
  {
    path: '/orders',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ Orders }) => Orders)
    ),
    exact: true,
  },
  {
    path: '/balance',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ Balance }) => Balance)
    ),
    exact: true,
  },
]
