import React from 'react'

import { GoodType } from '@monorepo/interfaces'
import { Image } from '@monorepo/components/common'
import { Link } from 'App/Components'

import placeholderImage from 'App/Resources/images/placeholder-300x300.png'

type PropsType = {
  item: GoodType
  onSelect?(): void
}

export const SuggestionItem: React.FC<PropsType> = ({ item, onSelect }) => (
  <Link
    className="suggestions__item suggestions__product text-decoration-none"
    to={item.searchLink}
  >
    <div className="suggestions__product-image">
      <Image
        height={40}
        width={40}
        className="img-thumbnail"
        lazy={false}
        src={item.thumbs?.[0] ?? placeholderImage}
      />
    </div>
    <div className="suggestions__product-info">
      <div className="suggestions__product-name">
        {item.brand.name} {item.number.toLocaleUpperCase()}
      </div>
      <div className="suggestions__product-rating">
        {/* <div className="suggestions__product-rating-stars">
          <div className="rating">
            <div className="rating__body">
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
            </div>
          </div>
        </div> */}
        <div className="suggestions__product-rating-label">
          {item.description}
        </div>
      </div>
    </div>
    {/* <div className="suggestions__product-price">$224.00</div> */}
  </Link>
)
