import { useMemo } from 'react'
import { FormatDateOptions, useIntl } from 'react-intl'

import { IAdvertisingCampaign } from './interfaces'

export function useFormattedPeriod(item?: IAdvertisingCampaign | null) {
  const { formatDate } = useIntl()

  return useMemo(() => {
    if (!item?.startAt && !item?.endAt) return undefined

    const formatPart = (dateTime: string, prefix?: string) =>
      [prefix, dateTime && formatDate(dateTime, options)]
        .filter(Boolean)
        .join(' ')

    const options: FormatDateOptions = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }

    return [
      item.startAt ? formatPart(item.startAt, 'с') : undefined,
      item.endAt ? formatPart(item.endAt, 'по') : undefined,
    ]
      .filter(Boolean)
      .join(' ')
  }, [formatDate, item?.endAt, item?.startAt])
}

export function getActionsUrl(item?: IAdvertisingCampaign) {
  return item ? `/actions/${item.slug}` : '/actions'
}
