import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentUser } from '@monorepo/core/user_module/useCurrentUser'
import { IAdvertisingCampaign } from '../interfaces'
import { useApi } from '../api/useApi'

export const QUERY_KEY = 'advertising_campaign'

export function useCampaing<TData = IAdvertisingCampaign | null>(
  slug: string,
  options?: UseQueryOptions<IAdvertisingCampaign | null, AxiosError, TData>
) {
  const { fetchCampaigBySlug } = useApi()
  const { data: user } = useCurrentUser()

  return useQuery(
    [QUERY_KEY, { user: user?.id, slug }] as any,
    fetchCampaigBySlug.bind(null, slug),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      retry: 1,
      // enabled: !isUserFetching,
      ...options,
    }
  )
}
