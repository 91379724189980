import React from 'react'
import ReactScrollUp from 'react-scroll-up'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'

import style from './style.module.scss'

type ScrollToTopProps = {
  //
}

export const ScrollToTop: React.FC<ScrollToTopProps> = () => (
  <ReactScrollUp showUnder={160} duration={1000} style={{ zIndex: 999 }}>
    <FontAwesomeIcon
      size="3x"
      icon={faArrowAltCircleUp}
      className={style.scrollToTopButton}
    />
  </ReactScrollUp>
)
