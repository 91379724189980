import { useClient } from '@monorepo/app_module'
import { fetchCampaignsList } from './fetch_campaigns_list.api'
import { fetchCampaigBySlug } from './fetch_campaign_by_slug.api'
import { fetchCampaignSettings } from './fetch_campaign_settings.api'

export function useApi() {
  const client = useClient()

  const api = {
    fetchCampaigBySlug: fetchCampaigBySlug(client),
    fetchCampaignSettings: fetchCampaignSettings(client),
    fetchCampaignsList: fetchCampaignsList(client),
  }

  return api
}
