import { CompanyType } from '@monorepo/interfaces'

export const initialState: CompanyType = {
  name: 'Auto Exclusive777 Service Inc',
  address: {
    country: 'РК',
    // region: '',
    city: 'г. Симферополь',
    street: 'ул. Садовая',
    building: 'д. 73',
    additional: '',
  },
  phones: [
    {
      number: '+7 (978) 725-97-07',
      name: 'Запчасти',
      isMain: true,
    },
    {
      number: '+7 (978) 725-87-07',
      name: 'Сервис',
      isMain: false,
    },
  ],
  socialNetworks: [
    {
      name: 'facebook',
      url:
        'https://www.facebook.com/Auto-Exclusive777-Service-733561336841601/',
    },
    {
      name: 'vk',
      url: 'https://vk.com/pnn777aes',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/exclusive777official/',
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/avtoservis_simf',
    },
    {
      name: 'telegram',
      url: 'https://t.me/exclusive777auto',
    },
  ],
  messagers: {
    skype: 'aes777.ru',

    viber: '+79787259707',
  },
  email: 'info@aes777.ru',
  businessHours: [[9, 18], [9, 18], [9, 18], [9, 18], [9, 18], [9, 16], null],
  mapPlace: {
    center: { lon: 44.971606, lat: 34.103909 },
    zoom: 17,
    url: 'https://yandex.ru/maps/-/CKuI7MPj',
  },
  offices: [
    {
      id: 1,
      name: 'Магазин на Садовой',
      isSalesPoint: true,
      address: {
        country: 'РК',
        // region: '',
        city: 'г. Симферополь',
        street: 'ул. Садовая',
        building: 'д. 73',
        additional: '',
      },
      phones: [
        {
          number: '+7 (978) 725-97-07',
          name: 'Запчасти',
          isMain: true,
        },
        {
          number: '+7 (978) 725-87-07',
          name: 'Сервис',
          isMain: false,
        },
      ],
      socialNetworks: [
        {
          name: 'facebook',
          url:
            'https://www.facebook.com/Auto-Exclusive777-Service-733561336841601/',
        },
        {
          name: 'vk',
          url: 'https://vk.com/pnn777aes',
        },
        {
          name: 'instagram',
          url: 'https://www.instagram.com/exclusive777official/',
        },
        {
          name: 'twitter',
          url: 'https://twitter.com/avtoservis_simf',
        },
        {
          name: 'telegram',
          url: 'https://t.me/exclusive777auto',
        },
      ],
      messagers: {
        skype: 'aes777.ru',

        viber: '+79787259707',
      },
      email: 'info@aes777.ru',
      businessHours: [
        [9, 18],
        [9, 18],
        [9, 18],
        [9, 18],
        [9, 18],
        [9, 16],
        null,
      ],
      mapPlace: {
        center: { lon: 44.971606, lat: 34.103909 },
        zoom: 17,
        url: 'https://yandex.ru/maps/-/CKuI7MPj',
      },
    },
  ],
}

// export default initialState
