import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { GoodType } from '@monorepo/interfaces'
import { SuggestionItem } from './SuggestionItem'

type PropsType = {
  className?: string
  height?: number | string
  open?: boolean
  items?: Array<GoodType>
  onSelect?(good: GoodType): void
  isFetching: boolean
  query?: string
}

const BaseSuggestionsList: React.FC<PropsType> = ({
  open = false,
  items = [],
  onSelect,
  className,
  isFetching,
  query,
}) => (
  <div
    className={cn(
      className,
      'w-100',
      'search__dropdown',
      'search__dropdown--suggestions',
      'suggestions',
      { 'search__dropdown--open': open },
      { 'd-none': !open }
    )}
  >
    {items.length > 0 ? (
      <div className="suggestions__group mt-2- mb-3- ml-3- mr-3-">
        <div className="suggestions__group-title">Наименования</div>
        <div className="suggestions__group-content">
          {items.map((item) => (
            <SuggestionItem
              key={item.id}
              item={item}
              onSelect={onSelect?.bind(null, item)}
            />
          ))}
        </div>
      </div>
    ) : (
      <div className="suggestions__group mt-2- mb-3- ml-3- mr-3-">
        <div className="suggestions__group-title">
          {(query?.length ?? 0) >= 2 ? (
            isFetching ? (
              <>Поиск «{query}»...</>
            ) : (
              <>По запросу «{query}» ничего не найдено.</>
            )
          ) : (
            <>Что ищем?</>
          )}
        </div>
      </div>
    )}
    {/* <div className="suggestions__group">
      <div className="suggestions__group-title">Categories</div>
      <div className="suggestions__group-content">
        <a className="suggestions__item suggestions__category" href="/">
          Headlights &amp; Lighting
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Fuel System &amp; Filters
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Body Parts &amp; Mirrors
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Interior Accessories
        </a>
      </div>
    </div> */}
  </div>
)

export const SuggestionsList = styled(BaseSuggestionsList)`
  max-height: ${(props) => (props.height ? `${props.height}` : 'inherit')};
  overflow-y: scroll;
  // padding: 12px;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  top: 100%;
`
