import { AxiosInstance } from 'axios'
import { IAdvertisingCampaignSettings } from '../interfaces'

export const fetchCampaignSettings = (client: AxiosInstance) => async () => {
  try {
    const { data } = await client.get<IAdvertisingCampaignSettings>(
      '/actions_campaign/settings'
    )
    return data
  } catch (error) {
    throw new Error((error as any).message)
  }
}
