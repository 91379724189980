import lazyPage from '@monorepo/core/hoc/lazyPage'

export default () => [
  {
    path: '/account/login',
    component: lazyPage(() =>
      import('App/View/Account').then(({ Login }) => Login)
    ),
    exact: true,
  },
  {
    path: '/account/logout',
    component: lazyPage(() =>
      import('App/View/Account').then(({ Logout }) => Logout)
    ),
    exact: true,
  },
  {
    path: '/account/registration',
    component: lazyPage(() =>
      import('App/View/Account').then(({ Registration }) => Registration)
    ),
    exact: true,
  },
  {
    path: '/account/reset-password',
    component: lazyPage(() =>
      import('App/View/Account').then(({ ResetPassword }) => ResetPassword)
    ),
    exact: true,
  },
  {
    path: '/account/change-password',
    component: lazyPage(() =>
      import('App/View/Cabinet').then(({ ChangePassword }) => ChangePassword)
    ),
    exact: true,
  },
  {
    path: '/resetting/reset/:token',
    component: lazyPage(() =>
      import('App/View/Account').then(({ ResetPassword }) => ResetPassword)
    ),
    exact: true,
  },
]
