import React from 'react'
import cn from 'classnames'
import { Col, Row, Container } from 'react-bootstrap'

import { App } from '@monorepo/hooks'
import { SocialNetworksList, Link, EmailLink, PhoneLink } from 'App/Components'
import { LinksColumn, LinksColumnProps } from './LinksColumn'

const leftColumn: LinksColumnProps = {
  title: 'Информация',
  items: [
    {
      name: 'Договор оферты',
      url: '/pages/oferta',
    },
    {
      name: 'Политика конфиденциальности',
      url: '/pages/privacy-policy',
    },
    {
      name: 'Обработка персональных данных',
      url: '/pages/processing-personal-data',
    },
    {
      name: 'Использование информации',
      url: '/pages/restrictions-of-use',
    },
    {
      name: 'Пользовательское соглашение',
      url: '/pages/terms-of-use',
    },
  ],
}

const rightColumn: LinksColumnProps = {
  title: 'Полезное',
  items: [
    {
      name: 'О нас',
      url: '/about-us',
    },
    {
      name: 'Контакты',
      url: '/contacts',
    },
    {
      name: 'Автосервис',
      url: 'http://aes777.ru',
    },
    {
      name: 'Аренда инструмента',
      url: '/pages/arenda_specinstrumenta',
    },
    {
      name: 'Доставка',
      url: '/pages/dostavka',
    },
    {
      name: 'Как оплатить заказ',
      url: '/pages/kak_oplatit_zazkaz',
    },
    {
      name: 'Обмен и возврат товара',
      url: '/pages/obmen_vozvrat_tovara',
    },
  ],
}

type ContentPropsType = {
  //
}

export const Content: React.FC<ContentPropsType> = () => {
  const { company } = App.useCompany()
  const businessHours = [
    {
      name: 'ПН-ПТ',
      startTime: '09:00',
      endTime: '18:00',
    },
    {
      name: 'СБ',
      startTime: '09:00',
      endTime: '16:00',
    },
    {
      name: 'ВС',
      isHoliday: true,
    },
  ]
  return (
    <div className="site-footer__widgets">
      <Container>
        <Row>
          <Col xl="4">
            <div className="site-footer__widget footer-contacts">
              <h5 className="footer-contacts__title">Наши контакты</h5>
              <div className="footer-contacts__text">
                Интернет-магазин автозапчастей в Симферополе
              </div>
              <address className="footer-contacts__contacts">
                <dl>
                  <dt>
                    {company.phones.length === 1
                      ? 'Наш телефон'
                      : 'Наши телефоны'}
                  </dt>
                  {company.phones.map((phone, idx) => (
                    <dd key={idx}>
                      <PhoneLink phone={phone.number} />
                    </dd>
                  ))}
                </dl>
                <dl>
                  <dt>Наш Email</dt>
                  <dd>
                    <EmailLink email={company.email}>{company.email}</EmailLink>
                  </dd>
                </dl>
                <dl>
                  <dt>Наш адрес</dt>
                  <dd>{company.address && company.address.fullAddress}</dd>
                </dl>
                <dl>
                  <dt>Время работы</dt>
                  <table>
                    <tbody>
                      {businessHours.map((day, idx) => (
                        <tr
                          key={idx}
                          className={cn('text-uppercase', {
                            // 'text-danger': day.isHoliday,
                          })}
                        >
                          <td className="text-right pr-4">{day.name}:</td>
                          <td
                            className={cn({
                              'text-center font-weight-bolder': day.isHoliday,
                            })}
                          >
                            {day.isHoliday ? (
                              'Выходной'
                            ) : (
                              <>
                                {day.startTime}&nbsp;&mdash;&nbsp;{day.endTime}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="text-light">
                      <tr>
                        <td colSpan={2}>
                          <hr className="bg-secondary w-100 my-2" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Не успели вовремя?</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="font-weight-bolder">
                          &mdash;&nbsp;&nbsp;Звоните, мы ответим!
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </dl>
              </address>
            </div>
          </Col>
          <Col xs="6" md="3" xl="2">
            <LinksColumn {...leftColumn} />
          </Col>
          <Col xs="6" md="3" xl="2">
            <LinksColumn {...rightColumn} />
          </Col>
          <Col xs="12" md="6" xl="4">
            <div className="site-footer__widget footer-newsletter">
              <h5 className="footer-newsletter__title">
                Зарегистрируйся сейчас!
              </h5>
              <div className="footer-newsletter__text">
                И получи гарантированную скидку на первый заказ
              </div>
              <form className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">
                  Email адрес
                </label>
                <input
                  type="text"
                  className="footer-newsletter__form-input"
                  id="footer-newsletter-address"
                  placeholder="Email адрес..."
                />
                <Link
                  to="/account/registration"
                  className="footer-newsletter__form-button pt-2"
                >
                  Регистрация
                </Link>
              </form>
              <div className="footer-newsletter__text footer-newsletter__text--social">
                Мы в социальных сетах
              </div>
              <div className="footer-newsletter__social-links social-links">
                <SocialNetworksList items={company.socialNetworks} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
