import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Link } from 'App/Components'
import mirLogo from 'App/Resources/images/payment-systems/mir-logo_original.svg'
import qiwiLogo from 'App/Resources/images/payment-systems/qiwi-wallet-logo_original.svg'

type PaymentSystem = {
  name: string
  icon: string
}

const paymentSystems: PaymentSystem[] = [
  {
    name: 'Карта Мир',
    icon: mirLogo,
  },
  {
    name: 'QIWI Кошелёк',
    icon: qiwiLogo,
  },
]

type BottomPropsType = {
  //
}

export const Bottom: React.FC<BottomPropsType> = () => (
  <div className="site-footer__bottom">
    <div className="container">
      <div className="site-footer__bottom-row">
        <div className="site-footer__copyright">
          {/* copyright */}
          Powered by&nbsp;&nbsp;&nbsp;
          <a
            title="Разработка интернет-магазинов автозапчастей"
            href="https://itonix.ru"
            rel="noopener"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src="https://itonix.ru/wp-content/uploads/2020/01/logo_itonix_gray_300px.png"
              width="100"
              alt="Разработка интернет-магазинов автозапчастей. Программа учета в магазине автозапчастей."
            />
          </a>
          {/* copyright / end */}
        </div>
        {/* <div className="site-footer__payments">
              <img src="images/payments.png" alt="" />
            </div> */}
        <div className="site-footer__payments d-flex align-items-center">
          <div className="mr-2">Принимаем к оплате:</div>
          {paymentSystems.map((item, idx) => (
            <OverlayTrigger
              key={idx}
              // placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {item.name}
                </Tooltip>
              )}
            >
              <Link to="/pages/kak_oplatit_zazkaz">
                <div className="rounded bg-light py-1 px-2 ml-1">
                  <img src={item.icon} height={20} alt={item.name} />
                </div>
              </Link>
            </OverlayTrigger>
          ))}
        </div>
      </div>
    </div>
  </div>
)
