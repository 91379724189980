import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { Cart, useComponentVisible } from '@monorepo/hooks'
import { Currency } from '@monorepo/components/common'
import { Link } from 'App/Components'

import { CartItemsList } from './CartItemsList'

type PropsType = {
  //
}

const StyledCartItemsList = styled(CartItemsList)`
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -12px;
  padding-right: 12px;
`

export const CartButton: React.FC<PropsType> = () => {
  const {
    cartItems,
    totalItems,
    totalPrice,
    removeCartItemHandler,
  } = Cart.useCart()
  const {
    isComponentVisible,
    componentRef,
    toggleComponentHandler,
  } = useComponentVisible(false)

  return (
    <div
      className={cn('indicator', 'indicator--trigger--click', {
        'indicator--open': isComponentVisible,
      })}
    >
      <button className="indicator__button" onClick={toggleComponentHandler}>
        <span className="indicator__icon">
          <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32}>
            <circle cx="10.5" cy="27.5" r="2.5" />
            <circle cx="23.5" cy="27.5" r="2.5" />
            <path
              d="M26.4,21H11.2C10,21,9,20.2,8.8,19.1L5.4,4.8C5.3,4.3,4.9,4,4.4,4H1C0.4,4,0,3.6,0,3s0.4-1,1-1h3.4C5.8,2,7,3,7.3,4.3
l3.4,14.3c0.1,0.2,0.3,0.4,0.5,0.4h15.2c0.2,0,0.4-0.1,0.5-0.4l3.1-10c0.1-0.2,0-0.4-0.1-0.4C29.8,8.1,29.7,8,29.5,8H14
c-0.6,0-1-0.4-1-1s0.4-1,1-1h15.5c0.8,0,1.5,0.4,2,1c0.5,0.6,0.6,1.5,0.4,2.2l-3.1,10C28.5,20.3,27.5,21,26.4,21z"
            />
          </svg>
          <span className="indicator__counter">{totalItems}</span>
        </span>
        <span className="indicator__title">Корзина</span>
        <span className="indicator__value">
          <Currency value={totalPrice} />
        </span>
      </button>
      <div className="indicator__content" ref={componentRef}>
        <div className="dropcart">
          {cartItems.length > 0 ? (
            <>
              <StyledCartItemsList
                items={cartItems}
                removeCartItemHandler={removeCartItemHandler}
                // style={{ maxHeight: '50vh', overflow: 'auto' }}
              />
              <div className="dropcart__totals">
                <table>
                  <tbody>
                    <tr>
                      <th>Итого</th>
                      <td>
                        <Currency value={totalPrice} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="dropcart__actions">
                <Link to="/cart" className="btn btn-primary">
                  Перейти в корзину
                </Link>
              </div>
            </>
          ) : (
            <div className="dropcart__actions"> Ваша корзина пуста</div>
          )}
        </div>
      </div>
    </div>
  )
}
